
import { mapGetters, mapState } from 'vuex'
import { defineAsyncComponent } from 'vue'
// import Banner from '~/components/Banner/banner.vue';
// import SampleProduct from '~/components/SampleProduct/SampleProduct.vue';
// import SmallBanner from '~/components/Banner/SmallBanner.vue';
// import Product from '~/components/Product/Product.vue';
// import ProductCategory from '~/components/Product/ProductCategory.vue';
// import CustomerFeedback from '~/components/Customer/CustomerFeedback.vue';
// import Promotion from '~/components/Promotion/Promotion.vue';
// import NeedHelpPopup from '~/components/UI/need-help-popup.vue'

export default {
  name: 'Homepage',
  components: {
    NeedHelpPopup: defineAsyncComponent(() => import('~/components/UI/need-help-popup.vue')),
    Banner: defineAsyncComponent(() => import('~/components/Banner/Banner.vue')),
    SampleProduct: defineAsyncComponent(() => import('~/components/SampleProduct/SampleProduct.vue')),
    SmallBanner: defineAsyncComponent(() => import('~/components/Banner/SmallBanner.vue')),
    Product: defineAsyncComponent(() => import('~/components/Product/Product.vue')),
    ProductCategory: defineAsyncComponent(() => import('~/components/Product/ProductCategory.vue')),
    CustomerFeedback: defineAsyncComponent(() => import('~/components/Customer/CustomerFeedback.vue')),
    Promotion: defineAsyncComponent(() => import('~/components/Promotion/Promotion.vue'))
    // NeedHelpPopup,
    // Banner,
    // SampleProduct,
    // SmallBanner,
    // Product,
    // ProductCategory,
    // CustomerFeedback,
    // Promotion
  },
  async asyncData ({ $axios, $replaceSEO }) {
    const seo = await $axios.get('sitemaps/home')
    const promoCode = await $axios.get('get-promo-codes')
    // get last promo code for show popup
    const lastPromoCode = await $axios.get('get-last-promocode-active')

    let promoCodePopup = null
    if (lastPromoCode) {
      promoCodePopup = lastPromoCode.data
    }

    const banners = await $axios.get('banners')

    return {
      promoCode: promoCode.data,
      promoCodePopup,
      seo: $replaceSEO(seo.data.data, null, null, null, null, null),
      banners: banners.data.banner_slide,
      homepage_features: banners.data.homepage_feature

    }
  },
  data () {
    return {
      waypointLoaded: [],
      slide: 0,
      tabLeft: false,
      tabRight: true,
      tabItem: 'all',
      videoUrlBanner: '',
      banners: [],
      homepage_features: [],
      sampleProductCategories: [],
      productGroupCategories: [],
      patternsSort: [],
      patternsCat: [],
      webmIsSupport: true,
      cover_video: [true, true, true],
      loadMore: false,
      articles: [],
      articles_promotion: []
    }
  },
  computed: {
    ...mapState(['userData']),
    ...mapGetters(['isAuthenticated']),
  }
}
