
export default {
  layout: 'landing',
  data () {
    return {
    }
  },
  mounted () {
    this.$sentry.captureMessage('Test Sentry Error1 env: ' + process.env.NODE_ENV)
    throw new Error('This is a test error!1 env >> ' + process.env.NODE_ENV)
  },
  methods: {
  }
}
